import EllipsisText from '@/components/EllipsisText';
import { getSourceBadge } from '@/components/Screener/helper';
import { SuffixHeaderType } from '@/components/Table/types';
import UnitBadge from '@/components/UnitBadge';
import '@/scss/components/tableCells/Header.css';
import {
  CaretSort,
  CaretSortDown,
  CaretSortUp,
  Draggable,
  DragHorizontal,
  Pin,
  PinFilled,
  ViewFilled,
} from '@carbon/icons-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortDirection } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PHeader } from '../helpers/types';
import useGetColumns from '../hooks/useGetColumns';

function SortComponent({
  isSorted,
  toggleSorting,
}: {
  isSorted: false | SortDirection;
  toggleSorting?: (event: unknown) => void;
}) {
  if (!isSorted)
    return (
      <div className="sort" onClick={toggleSorting}>
        <CaretSort size={16} />
      </div>
    );
  if (isSorted === 'asc')
    return (
      <div className="sort asc" onClick={toggleSorting}>
        <CaretSortUp size={16} />
      </div>
    );
  return (
    <div className="sort desc" onClick={toggleSorting}>
      <CaretSortDown size={16} />
    </div>
  );
}

export default function Header<T>({
  table,
  column,
  suffix,
  label,
  accessKey,
  header,
  source,
  maxPinLeft = 3,
}: PHeader<T> & SuffixHeaderType & { maxPinLeft?: number; maxPinRight?: number }) {
  const { t } = useTranslation();
  const Badge = useMemo(
    () => getSourceBadge(source ?? accessKey ?? label),
    [accessKey, label, source]
  );
  const Suffix = useMemo(() => (suffix ? t(suffix) : undefined), [suffix, t]);
  const Label = useMemo(() => (label ? t(`table_header.${label}`) : column.id), [label, column, t]);
  const { orderableColumns, pinnableColumns } = useGetColumns({ current: table });
  const isOrdable = useMemo(() => {
    return Boolean(orderableColumns.find((col) => col.id === column.id));
  }, [orderableColumns, column]);
  const isSortable = column.getCanSort();
  const isSorted = column.getIsSorted();
  const canPin = column.getCanPin();
  const isPinned = column.getIsPinned();
  const canResize = column.getCanResize();
  const canHide = column.getCanHide();

  const hasPinSlot = useMemo(() => {
    if (!maxPinLeft) return true;
    const pinnedcount = pinnableColumns.reduce((sum, col) => sum + Number(!!col.getIsPinned()), 0);
    return pinnedcount < maxPinLeft;
  }, [maxPinLeft, pinnableColumns]);

  const toggleSorting = useCallback(() => {
    const isSorted = column.getIsSorted();
    const isMulti = column.getCanMultiSort();
    if (isSorted === 'asc') column.toggleSorting(true, isMulti);
    else if (isSorted === 'desc') column.clearSorting();
    else column.toggleSorting(false, isMulti);
  }, [column]);

  const togglePin = useCallback(() => {
    const pinned = column.getIsPinned();
    if (column.getCanPin()) {
      if (!pinned) column.pin('left');
      else column.pin(false);
    }
  }, [column]);

  const { attributes, transition, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: column.id,
    disabled: !isOrdable,
  });

  const dragProps = useMemo(() => {
    if (isOrdable) return { ...attributes, ...listeners };
    return {};
  }, [attributes, listeners, isOrdable]);

  const hasRightSide = useMemo(
    () => Boolean(Suffix) || isOrdable || canPin || canResize || canHide,
    [Suffix, isOrdable, canPin, canResize, canHide]
  );

  return (
    <div
      className={`header-with-options${hasRightSide ? ' two-sides' : ''}`}
      ref={setNodeRef}
      style={{
        opacity: isDragging ? 0.5 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <div className="side left">
        {Boolean(Badge) && <div className="badge">{Badge}</div>}
        <div className="label">
          <EllipsisText label={Label} />
        </div>
      </div>
      {hasRightSide && (
        <div className="side right">
          {Boolean(Suffix) && (
            <div className="suffix">
              <UnitBadge unit={Suffix} />
            </div>
          )}
          {canPin && (
            <div className="pinner">
              {isPinned ? (
                <PinFilled onClick={canPin ? togglePin : undefined} />
              ) : (
                hasPinSlot && <Pin onClick={canPin ? togglePin : undefined} />
              )}
            </div>
          )}
          {isOrdable && (
            <div className="dragger">
              <Draggable {...dragProps} />
            </div>
          )}
          {canResize && (
            <div className="resizer">
              <DragHorizontal
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                onDoubleClick={() => column.resetSize()}
              />
            </div>
          )}
          {canHide && (
            <div className="hider">
              <ViewFilled onClick={() => column.toggleVisibility(false)} />
            </div>
          )}
          {isSortable && <SortComponent isSorted={isSorted} toggleSorting={toggleSorting} />}
        </div>
      )}
    </div>
  );
}
