import {
  de_default
} from "./chunk-GIU77Y7X.mjs";
import {
  en_default
} from "./chunk-AYRF4GNV.mjs";
import {
  fr_default
} from "./chunk-453FITRT.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
